<template>
  <fw-layout :full="isFullLayout" :back-to="backTo" management>
    <template #header-nav>
      <fw-heading size="lg" class="min-w-0 truncate">
        <span class="text-gray-500 mr-2">Férias</span>
        <span class="font-bold">{{ year }}</span>
      </fw-heading>
    </template>

    <template v-if="!view || view != 'maps'" #main-sidebar>
      <SidebarManageVacationsYear />
    </template>

    <template #main-content>
      <PanelManageVacationsYearDashboard v-if="!view || view == 'dashboard'" :year="year" />
      <PanelManageVacationsYearActivity v-if="!view || view == 'activity'" :year="year" />
      <PanelManageVacationsYearNotifications v-if="!view || view == 'notifications'" :year="year" />
      <PanelManageMaps
        v-else-if="view == 'maps'"
        :show-filters="false"
        :year="year"
        @searching="hideStatsOnSearching = true"
      />
      <PanelManageVacationsYearSync
        v-else-if="view == 'sync' && (permissions.isManager || permissions.isAdmin)"
        :year="year"
      />
      <PanelManageVacationsYearInbox v-else-if="view == 'inbox'" :year="year" />
      <PanelManageVacationsYearSettings v-else-if="view == 'settings'" :year="year" />
      <PanelManageVacationsYearSyncRights v-else-if="view == 'sync-rights-results'" :year="year" />
    </template>
  </fw-layout>
</template>

<script>
import PanelManageMaps from '@/components/panels/manage/PanelManageMaps.vue'
import PanelManageVacationsYearActivity from '@/components/panels/manage/PanelManageVacationsYearActivity.vue'
import PanelManageVacationsYearNotifications from '@/components/panels/manage/PanelManageVacationsYearNotifications.vue'
import PanelManageVacationsYearDashboard from '@/components/panels/manage/PanelManageVacationsYearDashboard.vue'
import PanelManageVacationsYearSync from '@/components/panels/manage/PanelManageVacationsYearSync.vue'
import PanelManageVacationsYearSettings from '@/components/panels/manage/PanelManageVacationsYearSettings.vue'
import PanelManageVacationsYearInbox from '@/components/panels/manage/PanelManageVacationsYearInbox.vue'
import SidebarManageVacationsYear from '@/components/sidebars/manage/SidebarManageVacationsYear.vue'
import PanelManageVacationsYearSyncRights from '@/components/panels/manage/PanelManageVacationsYearSyncRights.vue'

export default {
  components: {
    PanelManageMaps,
    PanelManageVacationsYearInbox,
    PanelManageVacationsYearSync,
    PanelManageVacationsYearSettings,
    PanelManageVacationsYearDashboard,
    SidebarManageVacationsYear,
    PanelManageVacationsYearNotifications,
    PanelManageVacationsYearActivity,
    PanelManageVacationsYearSyncRights
  },

  data() {
    return {
      hideStatsOnSearching: false
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },

    view() {
      return this.$route.meta.view ?? 'dashboard'
    },

    year() {
      return Number(this.$route.params.year)
    },

    isFullLayout() {
      return this.view && ['maps'].includes(this.view)
    },

    permissions() {
      return this.$store.getters.userPermissions
    },

    backTo() {
      if (this.view && ['maps'].includes(this.view)) {
        return `/manage/vacations/${this.year}`
      }
      return `/manage/vacations`
    }
  },

  watch: {
    year() {
      if (!this.year || this.year <= 1900 || isNaN(this.year)) {
        console.log('Invalid year', this.year)
        return this.$router.push({ name: 'manage' })
      }
    }
  },

  mounted() {
    if (!this.year || this.year <= 1900 || isNaN(this.year)) {
      console.log('Invalid year', this.year)
      return this.$router.push({ name: 'manage' })
    }
  },

  methods: {}
}
</script>
