<template>
  <div class="h-full flex flex-col">
    <div class="px-4 pt-2 pb-4">
      <div class="flex gap-5 justify-between items-center">
        <div class="flex flex-1 px-2">
          <fw-button
            size="md"
            :type="activeTab === 'person-maps' ? 'tab-active' : 'tab'"
            @click.native="getTabData('person-maps')"
          >
            Pessoas
            <span v-if="!loading" class="text-xs opacity-50 ml-2">{{
              activeTab === 'person-maps' ? totalResults : totalRemainingTabResults
            }}</span>
          </fw-button>
          <fw-button
            size="md"
            :type="activeTab === 'team-maps' ? 'tab-active' : 'tab'"
            @click.native="getTabData('team-maps')"
          >
            Equipas
            <span v-if="!loading" class="text-xs opacity-50 ml-2">{{
              activeTab === 'team-maps' ? totalResults : totalRemainingTabResults
            }}</span>
          </fw-button>

          <div
            v-if="(userPermissions.isManager || userPermissions.isAdmin) && userPermissions.isMapsManager"
            class="self-center ml-auto"
            @change="
              () => {
                save()
                getMaps()
              }
            "
          >
            <b-switch v-model="mine" size="is-small">
              Os meus mapas
            </b-switch>
          </div>
        </div>
        <div class="flex items-center">
          <div>
            <ContextualSearch
              v-if="showSearch"
              :restore-filters="true"
              type="minimal"
              :loading="loading"
              :filter-options="filters"
              :applied-filters="appliedFilters"
              :applied-sort="orderByValue"
              :applied-sort-direction="orderDirection"
              :multifilter="multifilter"
              :start-value="searchInput"
              :start-period="startPeriod"
              :range="datesRange"
              :end-period="endPeriod"
              :order-by-options="orderBy"
              :show-time-period="activeView == 'table'"
              :time-period-label="'Período'"
              :can-close="activeModal === null"
              @sort-order-changed="sortOrderChanged"
              @search="search"
            >
              <template v-if="activeMapFiltersCount > 0" #after-filters>
                <div class="flex items-center justify-between mx-4">
                  <div>
                    <fw-label class="pt-3 pb-2">
                      Mapas selecionados
                    </fw-label>
                    <fw-tag type="primary" class="ml-2">{{ activeMapFiltersCount }}</fw-tag>
                  </div>

                  <div class="h-5 w-5 cursor-pointer text-gray-400 hover:text-gray-800" @click="clearMapsFilters">
                    <fw-icon-close-circle class="fill-current h-5 w-5"></fw-icon-close-circle>
                  </div>
                </div>
              </template>
            </ContextualSearch>
          </div>
          <div
            v-if="
              showActions &&
                activeTab === 'team-maps' &&
                (userPermissions.isManager || userPermissions.isAdmin) &&
                mapsByStatusCount.closed > 0
            "
            class="border-l pl-2 "
          >
            <fw-menu-more>
              <b-dropdown-item paddingless>
                <fw-button :type="'dropdown-item'" @click.native="confirmOpenMaps">
                  Disponibilizar todos os mapas
                </fw-button>
              </b-dropdown-item>
            </fw-menu-more>
          </div>
          <div v-if="activeTab === 'person-maps'">
            <b-dropdown aria-role="list" position="is-bottom-left">
              <fw-button-dropdown
                slot="trigger"
                aria-role="listitem"
                type="xlight"
                label="Vista"
                class="font-medium text-gray-500"
              >
                <fw-icon-list-row class="h-4 w-4" /> {{ activeView == 'list' ? 'Ver em lista' : 'Ver em tabela' }}
              </fw-button-dropdown>
              <b-dropdown-item label="list" paddingless>
                <fw-button type="dropdown-item" @click.native="changeView('list')">Ver em lista </fw-button>
              </b-dropdown-item>
              <b-dropdown-item label="table" paddingless>
                <fw-button type="dropdown-item" @click.native="changeView('table')">Ver em tabela</fw-button>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div ref="availableSpace" class="flex-1 px-4">
      <LoadingPlaceholder v-if="loading" size="lg" />
      <template v-else>
        <div v-if="activeTab === 'team-maps'">
          <fw-panel-info
            icon
            left
            :type="activeMapFiltersCount > 0 ? 'warning' : 'basic'"
            class="-mt-3"
            custom-class="flex gap-5 justify-between items-center min-h-8"
          >
            <div v-if="activeMapFiltersCount > 0">
              Existem equipas selecionadas como filtro, o que irá permitir limitar a pesquisa de mapas de pessoas.
            </div>
            <div v-else>Filtre a lista de pessoas selecionado uma ou mais equipas.</div>
            <div v-if="activeMapFiltersCount > 0">
              <fw-button type="primary" size="xs" @click.native="getTabData('person-maps')">Filtrar pessoas</fw-button>
            </div>
          </fw-panel-info>
          <fw-panel-info v-if="!loading && (!maps || !maps.length)" clean empty>
            {{ $t('nomaps') }}
          </fw-panel-info>
          <div v-else class="bg-white p-1 rounded-xl">
            <div v-for="item in maps" :key="item.key" class="border-b border-gray-200 my-0.5 pb-0.5">
              <RecordManageMap
                :map="item"
                :user="users[item.user_key]"
                :selected="appliedFilters?.length ? appliedFilters.includes(`map_keys[]:${item.key}`) : false"
                @filter="toggleMapFilter"
              ></RecordManageMap>
            </div>
          </div>
        </div>
        <div v-else class="h-full">
          <fw-panel-info v-if="!loading && (!userMaps || !userMaps.length)" clean empty>
            {{ $t('nomaps') }}
          </fw-panel-info>
          <div
            v-else-if="activeView == 'list'"
            class="h-full"
            :class="{ 'bg-white p-1 rounded-xl': activeView == 'list' }"
          >
            <div v-for="item in userMaps" :key="item.key" class="border-b border-gray-200 my-0.5 pb-0.5">
              <RecordManageMapUser :item="item" :user="users[item.user_key]" />
            </div>
          </div>
          <div
            v-else-if="activeView == 'table' && activeTab != 'team-maps' && currentYearSelected"
            ref="tableParent"
            :class="{ 'bg-white p-1 rounded-xl': activeView == 'list' }"
            :style="{
              maxHeight: tableHeight + 'px',
              height: tableHeight + 'px',
              width: tableWidth + 'px',
              maxWidth: tableWidth + 'px'
            }"
          >
            <PanelStickyTable
              :year="Number(currentYearSelected)"
              :start-date="startPeriod"
              :end-date="endPeriod"
              :user-maps="userMaps"
              :height="tableHeight"
              :width="tableWidth"
              :users="users"
              :table-parent-ref-name="'tableParent'"
            />
            <!-- <template v-else-if="activeView == 'grid' && activeTab != 'team-maps' && currentYearSelected">
              <BlockYearTable />
            </template> -->
          </div>
        </div>
      </template>
    </div>
    <div v-if="totalPages > 1" class="px-4">
      <BlockPagination
        v-if="totalPages > 1 && !loading"
        :per-page="limit"
        :total="totalResults"
        :total-pages="totalPages"
        :current.sync="page"
        @page-changed="pageChanged"
      />
    </div>
  </div>
</template>

<script>
import ContextualSearch from '@/fw-modules/fw-core-vue/ui/components/search/ContextualSearch'
// import FilterTag from '@/fw-modules/fw-core-vue/ui/components/text/FilterTag.vue'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
// import BlockYearTable from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockYearTable'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import RecordManageMap from '@/components/records/manage/RecordManageMap'
import RecordManageMapUser from '@/components/records/manage/RecordManageMapUser'
import { MAP_USER_STATUS } from '@/utils/index.js'
import PanelStickyTable from '@/components/panels/manage/PanelStickyTable'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import _ from 'lodash'
export default {
  components: {
    ContextualSearch,
    // FilterTag,
    BlockPagination,
    LoadingPlaceholder,
    RecordManageMap,
    RecordManageMapUser,
    PanelStickyTable
    // BlockYearTable,
  },
  props: {
    showFilters: {
      type: Boolean,
      default: true
    },
    showSearch: {
      type: Boolean,
      default: true
    },
    showActions: {
      type: Boolean,
      default: true
    },
    year: {
      type: Number,
      default: null
    },
    multifilter: {
      type: Boolean,
      default: false
    },
    maxNumber: {
      type: Number,
      default: null
    }
  },

  data() {
    return {
      filterByType: 'all',
      // filters: [
      //   {
      //     key: 'status[]',
      //     label: 'Estado',
      //     options: [
      //       {
      //         key: 'reset',
      //         label: 'Tudo',
      //       },
      //       {
      //         key: 'open',
      //         label: 'Aberto',
      //       },
      //       {
      //         key: 'closed',
      //         label: 'Fechado',
      //       },
      //       {
      //         key: 'sealed',
      //         label: 'Lacrado',
      //       },
      //     ],
      //   },
      // ],

      orderBy: [
        {
          key: 'title',
          label: 'Titulo',
          type: 'string'
        },
        {
          key: 'user_name',
          label: 'Nome do trabalhador',
          type: 'string'
        }
      ],
      status: Object.freeze(MAP_USER_STATUS),
      searchInput: '',
      orderByValue: 'title',
      orderDirection: 'ASC',
      appliedFilters: [],
      startPeriod: null,
      endPeriod: null,
      page: 1,
      totalResults: 0,
      totalRemainingTabResults: 0,
      totalPages: 1,
      limit: 50,
      maps: [],
      mapsByStatusCount: {
        closed: 0,
        open: 0
      },
      userMaps: [],
      users: {},
      loading: true,

      activeModal: null,
      activeTab: 'person-maps',
      activeView: 'list',
      tableHeight: '500',
      tableWidth: '500',
      mine: true
    }
  },

  computed: {
    datesRange() {
      return [new Date(this.year, 0, 1), new Date(this.year, 11, 31)]
    },
    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },

    debugMode() {
      return Boolean(localStorage.getItem('fw-debug'))
    },

    currentYearSelected() {
      return this.year || this.appliedFilters.find(el => el.startsWith('year'))?.split(':')[1]
    },

    userPermissions() {
      return this.$store.getters.userPermissions
    },

    filters() {
      const filters = [
        {
          key: 'status[]',
          label: 'Estado',
          options: [
            {
              key: 'reset',
              label: 'Tudo'
            },
            {
              key: 'draft',
              label: this.status.draft['pt']
            },
            {
              key: 'submitted',
              label: this.status.submitted['pt']
            },
            {
              key: 'canceled',
              label: this.status.canceled['pt']
            },
            {
              key: 'approved',
              label: this.status.approved['pt']
            },
            {
              key: 'declined',
              label: this.status.declined['pt']
            },
            {
              key: 'pre_approved',
              label: this.status.pre_approved['pt']
            },
            {
              key: 'outdated',
              label: this.status.outdated['pt']
            }
          ]
        }
      ]

      if (this.activeTab == 'person-maps') {
        filters.push({
          key: 'sync_errors',
          label: 'Erros de sincronização',
          options: [
            {
              key: 'reset',
              label: 'Tudo'
            },
            {
              key: 'true',
              label: 'Com erros'
            }
          ]
        })
      }

      return filters
    },

    activeMapFiltersCount() {
      return this.appliedFilters?.filter(el => el.startsWith('map_keys[]'))?.length
    }
  },

  mounted() {
    this.getUrlParams()
    this.restore()
    // this.getMaps() // The restore on ContextualSearch is calling search() on mount so this is not needed
    //listen to window resize
    window.addEventListener('resize', _.debounce(this.calculateAvailableSpace, 1000))
  },

  beforeDestroy() {
    window.removeEventListener('resize', _.debounce(this.calculateAvailableSpace, 1000))
  },

  methods: {
    convertRemToPixels(rem) {
      return rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
    },
    async changeView(view) {
      this.startPeriod = null
      this.endPeriod = null
      //calculate available height for a table
      await this.getMaps()
      this.activeView = view
      this.calculateAvailableSpace()
    },
    calculateAvailableSpace() {
      if (this.activeView == 'table') {
        let availableHeight = window.innerHeight - 56 - 64 - 38.5 - 51 + 38
        this.tableHeight = availableHeight + 'px'
        this.limit = Math.floor(availableHeight / 50)
        //if (this.$refs.availableSpace) {
        //  this.tableWidth = this.$refs.availableSpace.offsetWidth - this.convertRemToPixels(2)
        //  console.log('tableWidth', this.tableWidth)
        //}
        this.tableWidth = window.innerWidth - this.convertRemToPixels(2) + 'px'
        console.log('tableHeight', this.tableHeight)
      }
    },
    getTabData(type) {
      this.startPeriod = null
      this.endPeriod = null
      this.activeTab = type
      this.calculateAvailableSpace()
      this.getMaps()
    },

    goToMap(key) {
      this.$router.push({ name: 'manage-map-users', params: { key: key } })
    },

    deleteFilter(index) {
      this.appliedFilters.splice(index, 1)
      this.setUrlParams()
      this.getMaps()
    },

    deleteDates() {
      this.startPeriod = null
      this.endPeriod = null
      this.setUrlParams()
      this.getMaps()
    },

    getFilterText(key) {
      if (key.startsWith('map_keys[]')) {
        const optionKey = key.split(':')[1]

        if (Array.isArray(this.maps)) {
          return this.maps?.find(el => el.key == optionKey)?.title ?? optionKey
        }

        return this.maps[optionKey]?.title ?? optionKey
      }
      return utils.getFilterText(key, this.filters)
    },

    search(data) {
      console.log('data search', data)
      this.appliedFilters = JSON.parse(JSON.stringify(data.filters))
      this.searchInput = data.term

      if (data.orderBy != null) {
        this.orderByValue = data.orderBy
        this.orderDirection = data.orderDirection
      }

      this.startPeriod = data.dates.length == 2 ? this.$options.filters.formatDate(data.dates[0]) : null
      this.endPeriod = data.dates.length == 2 ? this.$options.filters.formatDate(data.dates[1]) : null

      this.$emit('searching')

      this.setUrlParams()
      this.getMaps()
    },

    getUrlParams() {
      if (this.$route.query.query) {
        this.searchInput = this.$route.query.query
      }

      if (this.$route.query.f) {
        this.appliedFilters = this.$route.query.f.split(',')
      }

      if (this.$route.query.s) {
        this.orderByValue = this.$route.query.s
        this.orderDirection =
          this.$route.query.o == 'ASC' || this.$route.query.o == 'DESC' ? this.$route.query.o : 'none'
      }

      if (this.$route.query.p) {
        this.page = parseInt(this.$route.query.p)
      }

      if (this.$route.query.start) {
        this.startPeriod = this.$route.query.start
      }

      if (this.$route.query.end) {
        this.endPeriod = this.$route.query.end
      }
    },

    setUrlParams() {
      let query = {}
      if (this.searchInput.length > 0) {
        query['query'] = this.searchInput
      }

      if (this.appliedFilters.length > 0) {
        query['f'] = this.appliedFilters.join(',')
      }

      if (this.orderByValue.length > 0) {
        query['s'] = this.orderByValue
        query['o'] = this.orderDirection
      }

      if (this.startPeriod != null && this.endPeriod != null) {
        query['start'] = this.startPeriod
        query['end'] = this.endPeriod
      }

      query['p'] = this.page

      this.$router.push({ path: this.$route.path, query: query })
    },

    sortOrderChanged(newSort) {
      if (newSort != null && newSort.key != null) {
        this.orderByValue = newSort.key.key
        this.orderDirection = newSort.direction
      }
      this.setUrlParams()
      this.getMaps()
    },

    save() {
      localStorage.setItem(`toggle:${window.location.pathname}:mine`, this.mine.toString())
    },

    restore() {
      this.mine = localStorage.getItem(`toggle:${window.location.pathname}:mine`) === 'true'
    },

    async getMaps() {
      this.loading = true
      const maxNumber = this.maxNumber != null ? this.maxNumber : this.limit
      let query = { limit: maxNumber, page: this.page, ...utils.parseFiltersQuery(this.appliedFilters) }
      if (this.year) {
        query['year'] = this.year
      }
      if (this.orderByValue.length > 0) {
        query['sort'] = this.orderByValue
        query['direction'] = this.orderDirection.toLowerCase()
      }

      if (this.searchInput.length > 0) {
        query['query'] = this.searchInput
      }

      if (this.startPeriod && this.endPeriod) {
        query['created_start'] = this.parseDatesForPayload(this.startPeriod)
        query['created_end'] = this.parseDatesForPayload(this.endPeriod)
      }

      if ((this.userPermissions.isManager || this.userPermissions.isAdmin) && this.userPermissions.isMapsManager) {
        query['mine'] = this.mine
      }

      try {
        let result
        if (this.activeTab === 'team-maps') {
          query['count_map_users'] = true
          delete query['map_keys[]']
          result = await this.api.getManagerMaps(query)
          this.totalRemainingTabResults = result.map_users_count ?? 0
          this.mapsByStatusCount = result.stats
        } else {
          if (!query['sort']) {
            this.orderByValue = 'user_name'
          } else if (query['sort'] == 'title') {
            query['sort'] = 'user_name'
          }
          console.log('query', query)
          query['count_maps'] = true
          result = await this.api.getManagerMapsUsers(query)
          this.totalRemainingTabResults = result.map_count ?? 0
        }

        console.log('getMaps :>> ', result)
        this.totalResults = result.pagination?.total_items
        this.totalPages = result.pagination?.total_pages
        this.page = result.pagination?.current_page
        this.maps = result.maps
        this.userMaps = result.user_maps
        this.users = result.users

        if (!this.year) {
          if (!this.filters.find(el => el.key == 'year')) {
            this.filters.push({
              key: 'year',
              label: 'Ano',
              options: result.filters.years.map(year => {
                return {
                  key: year,
                  label: year.toString()
                }
              })
            })
          }
          const yearFilter = this.appliedFilters.find(el => el.startsWith('year'))
          if (!yearFilter) {
            console.log('add to filter', `year:${result.filters.years.at(-1)}`)
            this.appliedFilters.push(`year:${result.filters.years.at(-1)}`)
          }
        }
      } catch (error) {
        console.error('getMaps Error: ', error)
      }

      this.loading = false
    },

    pageChanged(page) {
      if (page) this.page = page
      this.setUrlParams()
      this.getMaps()
    },

    toggleMapFilter(map) {
      const key = `map_keys[]:${map.key}`

      if (this.appliedFilters.includes(key)) {
        this.appliedFilters = this.appliedFilters.filter(el => el != key)
      } else {
        this.appliedFilters.push(`map_keys[]:${map.key}`)
      }
    },

    clearMapsFilters() {
      this.appliedFilters = this.appliedFilters.filter(el => !el.startsWith('map_keys[]'))
    },

    parseDatesForPayload(formatedDate) {
      if (formatedDate) {
        return Dates.from(formatedDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
      }
      return formatedDate
    },

    async openMaps(year) {
      console.log('Open maps for year', year)
      await utils.tryAndCatch(this, async () => {
        const response = await this.api.updateManagerMaps({ year: year, status: 'open' })
        console.log('updateManagerMaps', response)
        this.$buefy.toast.open({
          message: `Disponibilizados ${response.maps?.length} mapas!`,
          type: 'is-success'
        })

        this.getMaps()
      })
    },

    confirmOpenMaps() {
      console.log('open')
      this.$buefy.dialog.confirm({
        confirmText: 'Disponibilizar',
        type: 'is-warning',
        cancelText: 'Cancelar',
        title: `Disponibilizar todos os mapas de ${this.year}`,
        message: `<div class="has-margin-bottom-small">Tem a certeza que deseja disponibilizar os <b>${this.mapsByStatusCount.closed} mapas de de ${this.year}, ainda por disponibilizar? Esta ação irá enviar uma notificação a todos os trabalhadores associados aos mesmos.`,
        onConfirm: () => {
          this.$buefy.dialog.prompt({
            confirmText: 'Disponibilizar',
            type: 'is-warning',
            cancelText: 'Cancelar',
            message: `Para concluir a disponibilização dos mapas, por favor, escreva "confirmo" na caixa seguinte e carregue novamente no botão "Disponibilizar".`,
            closeOnConfirm: false,
            inputAttrs: {
              placeholder: 'Escreva "confirmo"',
              maxlength: 8
            },
            trapFocus: true,
            onConfirm: (value, { close }) => {
              if (value.toLowerCase() == 'confirmo') {
                this.openMaps(this.year)
                close()
              }
            }
          })
        }
      })
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "createdDate": "Período",
    "results": "Resultados",
    "loadMoreData": "Ver mais resultados",
    "nomaps": "Não existem mapas para apresentar.",
    "spaceType": {
      "label": "Tipo de espaço",
      "development": "Desenvolvimento",
      "support": "Suporte"
    },
    "all": "Todos",
    "orderBy": {
      "shortName": "Nome do espaço",
      "spaceStatus": "Estado do espaço",
      "createdDate": "Data de criação",
      "spaceType": "Tipo de espaço",
      "title": "Titulo do espaço",
      "prefix": "Prefixo do espaço",
      "endDate": "Data de fim",
      "startDate": "Data de início"
    },
    "mapStatus": {
      "label": "Estado do procedimento",
      "canceled": "Cancelado",
      "published": "Publicado",
      "draft": "Rascunho"
    },
    "mapType": {
      "label": "Tipo de procedimento",
      "scholarship": "Apoio",
      "occupational_medicine": "Medicina do Trabalho",
      "internship": "Estágio",
      "job": "Emprego",
      "other": "Outro"
    }
  },
  "en": {
    "createdDate": "Dates range",
    "results": "Results",
    "nomaps": "No maps to present.",
    "loadMoreData": "Load more data",
    "spaceType": {
      "label": "Space type",
      "development": "Development",
      "support": "Support",
      "other": "Other"
    },
    "all": "All",
    "orderBy": {
      "shortName": "Space short name",
      "spaceStatus": "Space status",
      "createdDate": "Creation date",
      "spaceType": "Space type",
      "title": "Space title",
      "prefix": "Space prefix",
      "endDate": "End date",
      "startDate": "Start date"
    },
    "mapStatus": {
      "label": "map status",
      "canceled": "Cancelado",
      "published": "Published",
      "draft": "Draft"
    },
    "mapType": {
      "label": "map type",
      "scholarship": "Scholarship",
      "occupational_medicine": "Occupational Medicine",
      "internship": "Internship",
      "job": "Job",
      "other": "Other"
    }
  }
}
</i18n>

<style>
.date-table-range .datepicker-header {
  display: none;
}
.datepicker .dropdown-content {
  box-shadow: none !important;
  border: 1px solid #f1f2f4;
}
</style>
